import { Sidebar, Toast } from "@coreui/coreui-pro";
import * as Turbo from "@hotwired/turbo";
import "simplebar/dist/simplebar.min.js";

// Turboを無効化して必要なelementにdata-turbo="true"を追加する
Turbo.session.drive = false;

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

document.addEventListener("DOMContentLoaded", function () {
  setupSidebarToggle();
  showFlashToasts();
});

/**
 * navigationの開閉
 * @returns
 */
function setupSidebarToggle() {
  const headerTogglerButton = document.getElementById("headerTogglerButton");
  if (!headerTogglerButton) return;

  headerTogglerButton.addEventListener("click", function () {
    const sidebarElement = document.querySelector("#sidebar");
    if (sidebarElement) {
      const sidebar = Sidebar.getInstance(sidebarElement);
      sidebar.toggle();
    }
  });
}

/**
 * Flashのトースト表示
 */
function showFlashToasts() {
  const flashToasts = document.querySelectorAll(".flash-toast");
  flashToasts.forEach((toastElement) => {
    const toast = Toast.getOrCreateInstance(toastElement);
    toast.show();
  });
}
